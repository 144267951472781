import React from 'react';

import {HomePage} from "./pages/Home.page";
// import SmoothScroll from 'smooth-scroll';


/*export const scroll = new SmoothScroll('a[href*="#"]', {
    speed: 1000,
    speedAsDuration: true,
});*/

function App() {
    return (
        <div className="App">
            <HomePage/>
        </div>
    );
}

export default App;
