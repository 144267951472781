import React, {useState} from "react";
import ReactFlagsSelect from "react-flags-select";

const Navigation = () => {
    const [selected, setSelected] = useState("ES");

    return (
        <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
            <div className='container'>
                <div className='navbar-header'>
                    <button
                        type='button'
                        className='navbar-toggle collapsed'
                        data-toggle='collapse'
                        data-target='#bs-example-navbar-collapse-1'
                    >
                        {' '}
                        <span className='sr-only'>Toggle navigation</span>{' '}
                        <span className='icon-bar'/>{' '}
                        <span className='icon-bar'/>{' '}
                        <span className='icon-bar'/>{' '}
                    </button>
                    <a className='navbar-brand page-scroll' href='#page-top'>
                        Profe Judith Patsi Marinez
                    </a>
                </div>

                <div
                    className='collapse navbar-collapse'
                    id='bs-example-navbar-collapse-1'
                >
                    <ul className='nav navbar-nav navbar-right'>
                        <li>
                            <a href='#features' className='page-scroll'>
                                Features
                            </a>
                        </li>
                        <li>
                            <a href='#about' className='page-scroll'>
                                About
                            </a>
                        </li>
                        <li>
                            <a href='#services' className='page-scroll'>
                                Services
                            </a>
                        </li>
                        <li>
                            <a href='#portfolio' className='page-scroll'>
                                Gallery
                            </a>
                        </li>
                        <li>
                            <a href='#contact' className='page-scroll'>
                                Contact
                            </a>
                        </li>
                        <li>
                            <ReactFlagsSelect
                                countries={["ES", "GB", "CT"]}
                                customLabels={{ES: "ES", GB: "EN", CT: "CT"}}
                                placeholder="Select Language"
                                onSelect={(code) => setSelected(code)}
                                selected={selected}
                            />
                        </li>
                    </ul>
                </div>

            </div>
        </nav>
    )
}

export default Navigation;
